/* -------------- news --------------*/
.pg-news-list{
    @extend %clearStyle;
    .news{
        @include flex($ai:flex-start);
        border: 1px solid #f4f4f4;
        margin-bottom: 20px;
        &:hover{
            background: #f4f4f4;
            .date{
                background: var(--color-active);
            }
        }
        .date{
            background: var(--color-main);
            border-top-right-radius: 30px;
            color: var(--color-white);
            text-align: center;
            padding: 23px 5px;
            width: 100px;
            .day{
                font-size: 1.875rem /* 30/16 */;
                font-weight: bold;
            }
            .month{
                font-size: 1.125rem /* 18/16 */;
                line-height: 1;
                margin-top: 5px;
            }
        }
        .content{
            padding: 20px;
            width: calc(100% - 100px);
        }
        .content-head{
            border-bottom: 1px solid var(--color-grey);
            padding-bottom: 10px;
            margin-bottom: 10px;
            .category{
                border: 1px solid var(--color-grey);
                background: var(--color-white);
                border-radius: 30px;
                display: inline-block;
                font-size: .875rem /* 14/16 */;
                padding: 8px 15px;
                line-height: 1;
                margin-bottom: 5px;
            }
            .news-title{
                @include lineLimit(1);
                a{
                    color: var(--color-font);
                    font-size: 1.125rem /* 18/16 */;
                    font-weight: bold;
                    &:hover{
                        color: var(--color-active);
                    }
                }
            }
        }
        .des{
            @include lineLimit(1);
            color: var(--color-font-light);
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
        }
    }
}