/* -------------- footer --------------*/
.footer{
    @extend %clearStyle;
    .frame{
        max-width: $w_pc;
    }
}

.footer-contact-sec{
    --color-term: #93b6e9;

    background: #0c2853;
    padding: 35px 0;
    .frame{
        @include flex($ai:flex-end, $jfc:space-between);
    }
    .main-sec{
        width: calc(100% - 230px);
    }
    .ft-logo{
        display: block;
        width: 260px;
        img{
            display: block;
            width: 100%;
        }
    }
    .info-list{
        margin-top: 35px;
        margin-bottom: 25px;
        .info{
            color: var(--color-white);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.3;
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
    .term-sec{
        width: 230px;
    }
    .term-link{
        border: 1px solid var(--color-term);
        color: var(--color-term);
        display: block;
        font-size: .9375rem /* 15/16 */;
        line-height: 1;
        text-align: center;
        padding: 10px 5px;
        width: 200px;
        &:hover{
            background: var(--color-term);
            color: var(--color-main);
        }
    }
    .line-sec{
        align-items: flex-end;
        display: flex;
        .qrcode{
            width: 90px;
            img{
                display: block;
                width: 100%;
            }
        }
        .line-data{
            padding-left: 15px;
            .at{
                display: block;
                width: 70px;
            }
            p{
                color: var(--color-white);
                font-size: .9375rem /* 15/16 */;
                line-height: 1;
                margin-top: 10px;
            }
        }
    }
}

.footer-rights-sec{
    background: var(--color-font);
    padding: 13px 0;
    .frame{
        @include flex($ai:center, $jfc:space-between);
    }
    .rights{
        color: var(--color-white);
        font-size: .875rem /* 14/16 */;
        line-height: 1.2;
    }
    .designed{
        background: #595959;
        color: var(--color-white);
        display: inline-block;
        font-size: .8125rem /* 13/16 */;
        line-height: 1;
        padding: 5px 3px;
        b{
            color: var(--color-white);
        }
    }
}

.top-btn{
    @include size(80px);
    @include flex($ai:center, $jfc:center);
    border-radius: 50%;
    background: var(--color-main);
    position: fixed;
    right: 30px;
    bottom: 10vh;
    opacity: 0;
    &:hover{
        background: var(--color-active);
    }
    .box{
        color: var(--color-white);
        text-align: center;
        svg{
            font-size: 1.875rem /* 30/16 */;
        }
        p{
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            margin: 0;
            margin-top: 3px;
        }
    }
}