/* -------------- header --------------*/
header{
    position: sticky;
    top: 0;
    z-index: 2;
}
.header{
    @include flex($ai:center, $jfc:space-between);
    @extend %clearStyle;
    background: var(--color-white);
    box-shadow: 0 3px 5px rgba($c_font, .25);
    padding: 30px 60px;
    .main-logo{
        display: block;
        width: 290px;
        img{
            display: block;
            width: 100%;
        }
    }
    .main-menu{
        @include flex($ai:baseline);
        gap: 0 50px;
        li{
            &.active a{
                color: var(--color-main);
                font-weight: bold;
            }
        }
        .main-title{
            color: var(--color-font);
            font-size: 1.125rem /* 18/16 */;
            line-height: 1.2;
            &:hover{
                color: var(--color-active);
            }
        }
    }
}


.mobile-toggle {
    @include size(28px, 30px);
    background: none;
    display: none;
    padding: 0;
    z-index: 4;

    &.open {

        span,
        span:before,
        span:after {
            background: var(--color-active);
        }

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        transition: all .4s ease;
    }
}