/* -------------- news RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .pg-news-list{
        .news{
            .date{
                border-top-right-radius: 20px;
                padding: 13px 5px;
                width: 70px;
                .day{
                    font-size: 1.375rem /* 22/16 */;
                }
                .month{
                    font-size: .9375rem /* 15/16 */;
                }
            }
            .content{
                padding: 10px;
                width: calc(100% - 70px);
            }
            .content-head{
                .news-title{
                    a{
                        font-size: 1.0625rem /* 17/16 */;
                    }
                }
            }
            .des{
                font-size: .9375rem /* 15/16 */;
            }
        }
    }
}

// @media (max-width: $w_mo){ }