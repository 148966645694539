/* -------------- qa RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .pg-qa-list .qa{
        .qa-head{
            padding: 15px;
            padding-right: 50px;
            &:after{
                font-size: 1.625rem /* 26/16 */;
            }
            p{
                font-size: 1rem /* 16/16 */;
            }
            .no{
                width: 40px;
            }
        }
        .qa-content{
            padding: 15px;
        }
    }
}

// @media (max-width: $w_tb){ }

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }