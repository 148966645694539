/* -------------- download --------------*/
.pg-download-list{
    @extend %clearStyle;
    .download{
        align-items: center;
        border-bottom: 1px dashed #e7e7e7;
        display: flex;
        padding: 15px 20px;
        &:hover{
            background: #f7f7f7;
            .download-btn{
                opacity: 1;
            }
        }
        .download-title{
            width: calc(100% - 120px - 50px);
            a{
                color: var(--color-font);
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1.4;
                &:hover{
                    color: var(--color-active);
                    text-decoration: underline;
                }
            }
        }
        .date{
            color: var(--color-font-light);
            font-size: .9375rem /* 15/16 */;
            flex-shrink: 0;
            line-height: 1;
            text-align: center;
            width: 120px;
            svg{
                margin-right: 5px;
            }
        }
        .download-btn{
            color: #426fb4;
            font-size: 1.5rem /* 24/16 */;
            flex-shrink: 0;
            opacity: .8;
            text-align: right;
            width: 50px;
        }
    }
}