/* -------------- contact RWD --------------*/
@media (max-width: $w_pc_b){ 
    .pg-contact .contact-man{
        padding: 35px 20px;
        .icon{
            width: 70px;
        }
        .content{
            width: calc(100% - 70px);
        }
    }
}

@media (max-width: $w_pc){ 
    .pg-contact .contact-man .content .info-list .info{
        span, a{
            font-size: 1rem /* 16/16 */;
        }
    }
}

@media (max-width: $w_pc_m){ 
    .pg-contact{
        .pg-contact-list{
            @include maw(640px);
            margin-left: auto;
            margin-right: auto;
            padding: 0;
        }
        .contact-man{
            flex: inherit;
            width: 100%;
        }
    }
}

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .pg-contact .contact-man{
        padding: 25px 20px;
        .icon{
            width: 50px;
        }
        .content{
            padding-left: 10px;
            width: calc(100% - 50px);
        }
    }
}

// @media (max-width: $w_mo){ }