$c_font: #231815;
$c_fontL: #636262;
$c_white: #fff;
$c_main: #103f85;
$c_dark: #334a8d;
$c_active: #15b399;
$c_grey: #a6a6a6;
$c_grey_bg: #e5e5e5;

$w_pc_b: calc(1440px + 40px);
$w_pc: 1280px;
$w_pc_m: 1024px;
$w_tb_b: 960px;
$w_tb: 768px;
$w_tb_m: 640px;
$w_mo: 480px;
$w_i6p: 414px;
$w_i6: 375px;
$w_mo_m: 320px;

$fz: 10px;

$fa-font-path: "../webfonts";