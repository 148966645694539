/* -------------- index --------------*/
.index-head{
    @include flex($ai:center, $jfc:space-between);
    margin-bottom: 35px;
    &.active-color .title-text span{
        color: var(--color-active);
    }
    &.main-color .title-text span{
        color: var(--color-main);
    }
    &.center{
        justify-content: center;
    }
    .title-text{
        color: var(--color-font);
        font-size: 1.5rem /* 24/16 */;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 1px;
        span{
            font-size: 1.875rem /* 30/16 */;
        }
    }
    .link{
        border: 1px solid var(--color-grey);
        border-radius: 30px;
        color: var(--color-grey);
        display: inline-block;
        font-size: 1rem /* 16/16 */;
        line-height: 1.2;
        padding: 9px 30px;
        &:hover{
            background: var(--color-grey);
            color: var(--color-white);
        }
        svg{
            padding-left: 5px;
        }
    }
}

.index-banner{
    img{
        display: block;
        width: 100%;
    }
}

.index-video-news{
    @extend %clearStyle;
    background: url(../images/index-bg-video.jpg) repeat-y;
    background-position: center center;
    padding: 60px 0;
    .frame{
        @include flex;
        > section{
            width: 50%;
        }
    }
    .video-sec{
        padding-right: 50px;
    }
    .video-box{
        border-radius: 40px;
        box-shadow: 0 25px 0px -15px #dcdfe2;
        overflow: hidden;
    }
    .video-content{
        @include flex($ai:flex-start);
        margin-top: 50px;
        padding-left: 20px;
        padding-right: 20px;
        .main-text{
            width: 47%;
            .video-title{
                color: var(--color-font);
                font-size: 1.5rem /* 24/16 */;
                line-height: 1.4;
                &:hover{
                    color: var(--color-active);
                }
            }
            .date{
                color: var(--color-font-light);
                font-size: .875rem /* 14/16 */;
                line-height: 1.2;
                margin-top: 15px;
            }
        }
        .des{
            @include lineLimit(3);
            color: var(--color-font-light);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.6;
            padding-left: 30px;
            width: 53%;
        }
    }
    .news-sec{
        padding-left: 100px;
    }
    .news{
        @include flex($ai:center);
        border-bottom: 1px solid #cccccc;
        padding: 20px 0;
        &:first-child{
            padding-top: 0;
        }
        &:nth-child(2n){
            .date{
                background: var(--color-active);
            }
        }
        .date{
            border-top-right-radius: 30px;
            background: var(--color-main);
            color: var(--color-white);
            padding: 12px 10px;
            text-align: center;
            width: 100px;
            .day{
                font-size: 1.875rem /* 30/16 */;
                font-weight: bold;
                line-height: 1;
            }
            .month{
                font-size: 1.125rem /* 18/16 */;
                font-weight: lighter;
                margin-top: 3px;
            }
        }
        .content{
            padding-left: 15px;
            width: calc(100% - 100px);
        }
        .content-head{
            align-items: center;
            display: flex;
            .category{
                border: 1px solid var(--color-font-light);
                color: var(--color-font-light);
                font-size: .875rem /* 14/16 */;
                flex-shrink: 0;
                padding: 5px 7px;
                line-height: 1;
                margin-right: 5px;
            }
            .news-title{
                @include lineLimit(1);
                a{
                    color: var(--color-font);
                    font-size: 1.0625rem /* 17/16 */;
                    font-weight: bold;
                    line-height: 1;
                    &:hover{
                        color: var(--color-active);
                    }
                }
            }
        }
        .des{
            @include lineLimit(1);
            color: var(--color-font-light);
            font-size: .9375rem /* 15/16 */;
            line-height: 1.4;
            margin-top: 8px;
        }
    }
}

.index-download{
    @extend %clearStyle;
    background: url(../images/index-bg-dl.jpg) #ebf3fe;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 60px 0;
    .download-list{
        @include maw(1040px);
        @include flex($jfc:space-between);
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
    }
    .download{
        width: 190px;
        &:hover .icon{
            box-shadow: -1.67px 5px 10px 0px rgba(8, 1, 3, 0.2);
        }
        .icon{
            @include size(190px);
            border: 1px solid var(--color-grey-light);
            background: var(--color-white);
            border-radius: 40px;
            box-shadow: -1.67px 11.883px 49px 0px rgba(8, 1, 3, 0.08);
            position: relative;
            transition: all .4s ease;
            img{
                @extend %center;
                display: block;
                width: 53%;
            }
        }
        .download-title{
            color: var(--color-font);
            font-size: 1.375rem /* 22/16 */;
            line-height: 1.4;
            margin-top: 20px;
            text-align: center;
            padding: 0 10px;
        }
    }
}

.index-contact{
    @extend %clearStyle;
    padding: 50px 0;
    .frame{
        @include flex($ai:center, $jfc:space-between);
    }
    .main-data{
        width: 48%;
    }
    .data-list{
        @include flex;
        margin: -8px;
        .data-box{
            padding: 8px;
            margin-top: 30px;
            width: 50%;
        }
        .box{
            border-top-right-radius: 30px;
            border: 1px solid var(--color-grey);
            padding: 40px 10px 40px 25px;
            position: relative;
            .icon{
                @include abs_pos($t:0, $l:25px);
                @include size(50px);
                @include flex($ai:center, $jfc:center);
                background: var(--color-grey);
                border-radius: 50%;
                transform: translateY(-50%);
                img{
                    display: block;
                    width: 30px;
                }
            }
            .info{
                display: flex;
                p{
                    color: var(--color-font);
                    font-size: 1.125rem /* 18/16 */;
                    line-height: 1.4;
                }
                .title{
                    color: var(--color-font-light);
                    flex-shrink: 0;
                    padding-right: 10px;
                }
            }
        }
    }
    .map-data{
        width: 50%;
        img{
            display: block;
            width: 100%;
        }
    }
}

.index-links{
	@extend %clearStyle;
    background: var(--color-grey-light);
    padding: 70px 0;
    .link-slider{
        margin: 0 -10px;
        position: relative;
    }
    .img-box{
        padding: 0 10px;
    }
    .slick-arrow{
        @include abs_pos($t:50%);
        background: none;
        color: var(--color-font);
        font-size: 1.5rem /* 24/16 */;
        padding: 0;
        transform: translateY(-50%);
        z-index: 1;
        &:hover{
            color: var(--color-active);
        }
    }
    .slick-prev{
        left: -15px;
    }
    .slick-next{
        right: -15px;
    }
}