/* -------------- header RWD --------------*/
@media (max-width: $w_pc_b){ 
    .header{
        padding: 30px 40px;
        .main-menu{
            gap: 0 35px;
        }
    }
}

@media (max-width: $w_pc){ 
    .header{
        padding: 20px 40px;
        .main-logo{
            width: 260px;
        }
        .main-menu{
            gap: 0 20px;
            .main-title{
                font-size: 1.0625rem /* 17/16 */;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .header{
        padding: 20px 40px;
        nav{
            background: rgba(black, .75);
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            height: 100dvh;
            overflow-y: auto;
            width: 100%;
        }
        .main-menu{
            background: $c_white;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            padding: 60px 30px 40px;
            gap: 15px 30px;
        }
    }

    .mobile-toggle{
        display: block;
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .header{
        padding: 20px;
    }
}

@media (max-width: $w_tb_m){ 
    .header{
        padding: 15px 20px;
        .main-menu{
            padding-left: 20px;
            padding-right: 20px;
            gap: 20px 0;
            li{
                text-align: center;
                width: 50%;
            }
        }
        .main-logo{
            width: 240px;
        }
    }
}

// @media (max-width: $w_mo){ }