/* -------------- footer RWD --------------*/
@media (max-width: $w_pc_b){ 
    .top-btn{
        @include size(60px);
        right: 10px;
        .box{
            svg{
                font-size: 1.25rem /* 20/16 */;
            }
        }
    }
}

@media (max-width: $w_pc){ 
    .footer-contact-sec{
        .info-list{
            margin-top: 25px;
            .info{
                font-size: .9375rem /* 15/16 */;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .footer-rights-sec{
        .rights{
            font-size: .8125rem /* 13/16 */;
            margin-bottom: 5px;
            width: 100%;
        }
    }
}

@media (max-width: $w_tb_b){ 
    .footer-contact-sec{
        .main-sec{
            margin-bottom: 20px;
            width: 100%;
        }
    }
}

@media (max-width: $w_tb){ 
    .top-btn{
        @include size(45px);
        right: 0;
        .box{
            svg{
                font-size: 1rem /* 16/16 */;
            }
            p{
                font-size: .75rem /* 12/16 */;
                margin-top: 0;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .footer-contact-sec{
        padding: 25px 0;
        .ft-logo{
            width: 240px;
        }
        .info-list{
            margin-top: 20px;
            margin-bottom: 20px;
            .info{
                .title{
                    color: var(--color-active);
                    display: block;
                    margin-bottom: 3px;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }