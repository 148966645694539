/* -------------- contact --------------*/
.pg-contact{
    @extend %clearStyle;
    .frame{
        max-width: 1680px;
    }
    .pg-contact-list{
        @include flex;
        padding: 30px 0;
    }
    .contact-man{
        border: 1px solid #ddd;
        background: var(--color-white);
        display: flex;
        flex: 1;
        padding: 55px 40px;
        &.dark-style{
            background: #385b8f;
            border-color: #385b8f;
            .content{
                .name{
                    color: var(--color-white);
                }
                .info-list{
                    svg, span, a{
                        color: var(--color-white);
                    }
                }
            }
        }
        .icon{
            width: 80px;
            img{
                display: block;
                width: 100%;
            }
        }
        .content{
            padding-left: 20px;
            width: calc(100% - 80px);
            .name{
                border-bottom: 1px solid #ddd;
                color: var(--color-font);
                font-size: 1.375rem /* 22/16 */;
                line-height: 1;
                padding-bottom: 10px;
            }
            .info-list{
                margin-top: 15px;
                .info{
                    align-items: baseline;
                    display: flex;
                    margin-top: 10px;
                }
                svg{
                    color: #999;
                    font-size: .9375rem /* 15/16 */;
                    flex-shrink: 0;
                    margin-right: 5px;
                }
                span, a{
                    color: var(--color-font-light);
                    font-size: 1.0625rem /* 17/16 */;
                    line-height: 1.2;
                }
                a:hover{
                    color: var(--color-active);
                    text-decoration: underline;
                }
            }
        }
    }
}