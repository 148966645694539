/* -------------- download RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .pg-download-list{
        .download{
            flex-wrap: wrap;
            padding: 10px 0;
            .download-title{
                margin-bottom: 5px;
                width: 100%;
            }
            .date, .download-btn{
                text-align: left;
            }
        }
    }
}

// @media (max-width: $w_mo){ }