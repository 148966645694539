/* -------------- layout --------------*/
:root {
	--color-main: #103f85;
    --color-active: #15b399;
    --color-font: #231815;
    --color-font-light: #636262;
    --color-grey: #a6a6a6;
	--color-grey-light: #e5e5e5;
	--color-white: #fff;
}

* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	padding: 0;
	margin: 0 auto;
}

body{
	font-family: 'Work Sans', Arial, 'Noto Sans TC', 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}

a, button{
	text-decoration: none;
	transition: all .4s ease;
}

.unreset-row{
	margin: 40px 0;
}

.unreset{
	line-height: initial;
	a{
		color: var(--color-font);
		display: inline-block;
		text-decoration: underline;
		transition: none;
		padding-right: 10px;
		padding-bottom: 3px;
		&:hover{
			color: var(--color-main);
		}
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
	p{
		color: var(--color-font);
		font-size: 1.0625rem /* 17/16 */;
		line-height: 1.6;
	}
}

.img-limit{
	position: relative;
	&.link-img{
		@include fiximg(100%, calc(70 / 210 * 100%));
	}
}

.page-banner{
	img{
		display: block;
		width: 100%;
	}
}

.breadcrumb{
	background: url(../images/bg-breadcrumb.jpg) no-repeat;
	background-position: left center;
	padding: 20px 0;
	.frame{
		@include flex;
		margin: 0 auto;
		list-style: none;
	}
	li{
		&:not(:last-child){
			&:after{
				content: '/';
				font-size: .9375rem /* 15/16 */;
				color: #aaa;
				margin: 0 5px;
			}
		}
	}
	a{
		color: var(--color-font);
		font-size: .9375rem /* 15/16 */;
		line-height: 1.2;
		&:hover{
			color: var(--color-active);
		}
	}
}

.page-main-row{
	padding: 60px 0;
	.frame{
		@include flex($ai:flex-start);
	}
}

aside{
	@extend %clearStyle;
	width: 240px;
	.aside-head{
		margin-bottom: 20px;
		.main{
			color: var(--color-font);
			font-size: 1.5rem /* 24/16 */;
			line-height: 1;
		}
		.en{
			color: var(--color-font-light);
			font-size: .9375rem /* 15/16 */;
			line-height: 1.2;
			text-transform: uppercase;
			margin-top: 7px;
		}
	}
	.aside-list{
		li{
			border-bottom: 1px solid #eeeeee;
			&.active{
				border-bottom: none;
				a{
					background: var(--color-active);
					color: var(--color-white);
				}
			}
			a{
				background: var(--color-white);
				color: var(--color-font);
				display: block;
				font-size: 1.125rem /* 18/16 */;
				line-height: 1.4;
				padding: 15px 20px;
				&:hover{
					background: var(--color-main);
					color: var(--color-white);
				}
			}
		}
	}
}
.page-main{
	padding-left: 60px;
	width: calc(100% - 240px);
	&.wide{
		padding-left: 0;
		width: 100%;
	}
	.page-head{
		@extend %clearStyle;
		position: relative;
		margin-bottom: 35px;
		&:before{
			@include abs_pos($l:0, $b:-2px);
			@include size(30px, 5px);
			background: linear-gradient(90deg, var(--color-main) 10px, var(--color-active) 10px);
			content: '';
		}
		.title{
			border-bottom: 1px solid #e7e7e7;
			color: var(--color-font);
			font-size: 1.4375rem /* 23/16 */;
			font-weight: bold;
			line-height: 1.3;
			padding-bottom: 12px;
		}
	}
	.page-detail-head{
		@extend %clearStyle;
		.title{
			color: var(--color-font);
			font-size: 1.375rem /* 22/16 */;
			font-weight: bold;
			line-height: 1.4;
		}
		.date{
			color: var(--color-main);
			font-size: .875rem /* 14/16 */;
			line-height: 1;
			margin-top: 5px;
		}
	}
}

.share-sns{
	.a2a_kit{
		display: flex;
	}
	a{
		@include size(30px);
		display: block;
		opacity: 1;
		&:not(:last-child){
			margin-right: 10px;
		}
		&:hover{
			opacity: .7;
		}
		img{
			display: block;
			width: 100%;
		}
	}
}

.other-relative{
	border-top: 1px solid #e7e7e7;
	margin-top: 40px;
	.row{
		@include flex($ai:flex-start);
		border-bottom: 1px solid #e7e7e7;
		padding: 13px 20px;
		&:first-child{
			background: #f6f6f6;
		}
		.title{
			color: var(--color-font-light);
			font-size: 1rem /* 16/16 */;
			line-height: 1.4;
			margin: 0;
			width: 70px;
		}
		.link{
			width: calc(100% - 70px);
			a{
				color: var(--color-font-light);
				font-size: 1rem /* 16/16 */;
				line-height: 1.4;
				&:hover{
					color: var(--color-active);
				}
			}
		}
	}
}

.back-btn{
	background: var(--color-main);
	color: var(--color-white);
	display: block;
	text-align: center;
	padding: 13px;
	margin: 40px auto 0;
	width: 160px;
	&:hover{
		background: var(--color-active);
	}
}