/* -------------- layout RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    aside{
        width: 200px;
        .aside-list li a{
            padding: 12px 15px;
        }
    }
    .page-main{
        padding-left: 40px;
        width: calc(100% - 200px);
    }
}

@media (max-width: $w_pc_m){ 
    aside{
        margin-bottom: 30px;
        width: 100%;
        .aside-list{
            @include flex;
            li{
                border: 1px solid var(--color-grey-light);
                &.active{
                    border-color: var(--color-active);
                }
                a{
                    font-size: 1rem /* 16/16 */;
                    padding: 10px 15px;
                }
            }
        }
    }
    .page-main{
        padding-left: 0;
        width: 100%;
    }
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-main-row{
        padding: 50px 0;
    }
}

@media (max-width: $w_tb_m){ 
    .breadcrumb{
        padding: 10px 0;
        a{
            font-size: .8125rem /* 13/16 */;
        }
    }
    aside .aside-list li a{
        padding: 10px;
    }
    .page-main{
        .page-head{
            margin-bottom: 25px;
        }
        .page-detail-head{
            .title{
                font-size: 1.25rem /* 20/16 */;
            }
        }
    }
    .page-main-row{
        padding: 40px 0;
    }
    .other-relative{
        margin-top: 30px;
        .row{
            padding: 10px;
            .title{
                font-size: .875rem /* 14/16 */;
                width: 60px;
            }
            .link{
                width: calc(100% - 60px);
                a{
                    font-size: .875rem /* 14/16 */;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }