/* -------------- qa --------------*/
.pg-qa-list{
    .qa{
        margin-bottom: 20px;
        .qa-head{
            @extend %clearStyle;
            align-items: baseline;
            background: var(--color-white);
            border: 1px solid var(--color-grey-light);
            cursor: pointer;
            display: flex;
            padding: 15px 30px;
            padding-right: 50px;
            position: relative;
            &:hover{
                background: #f6f6f6;
            }
            &:after{
                @include abs_pos($t:13px, $r:13px);
                color: #cccccc;
                content: '\f055';
                font-family: 'Font Awesome 6 Free';
                font-weight: bold;
                font-size: 1.875rem /* 30/16 */;
            }
            &.open{
                background: #375c94;
                border-color: #375c94;
                &:after{
                    content: '\f056';
                    color: var(--color-white);
                }
                p{
                    color: var(--color-white);
                }
            }
            p{
                color: var(--color-font);
                font-size: 1.1875rem /* 19/16 */;
                line-height: 1.4;
            }
            .no{
                flex-shrink: 0;
                width: 50px;
            }
            .qa-title{
                width: 100%;
            }
        }
    }
    .qa-content{
        border: 1px solid var(--color-grey-light);
        border-top: none;
        display: none;
        padding: 15px 30px;
    }
}