/* -------------- index RWD --------------*/
@media (max-width: $w_pc_b){ 
    .index-video-news{
        .news-sec{
            padding-left: 50px;
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: 1200px){ 
    .index-video-news{
        background: none;
        .frame{
            > section{
                width: 100%;
            }
        }
        .video-sec{
            
            padding-right: 0;
        }
        .news-sec{
            padding-left: 0;
            margin-top: 60px;
        }
    }
    .index-download{
        .download-list{
            max-width: 100%;
        }
        .download{
            width: 160px;
            .icon{
                @include size(160px);
            }
            .download-title{
                font-size: 1.25rem /* 20/16 */;
                padding: 0;
            }
        }
    }
    .index-contact{
        .data-list{
            .data-box{
                margin-top: 20px;
                width: 100%;
            }
            .box{
                padding: 40px 10px 20px 25px;
            }
        }
    }
}

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .index-download{
        .download{
            width: 120px;
            .icon{
                @include size(120px);
            }
            .download-title{
                font-size: 1.0625rem /* 17/16 */;
            }
        }
    }
}

@media (max-width: $w_tb){ 
    .index-video-news {
        .video-content {
            margin-top: 30px;
            .main-text{
                width: 100%;
            }
            .des{
                padding-left: 0;
                margin-top: 10px;
                width: 100%;
            }
        }
    }
    .index-contact{
        .main-data{
            width: 100%;
        }
        .data-list{
            .data-box{
                width: 50%;
            }
        }
        .map-data{
            margin-top: 30px;
            width: 100%;
        }
    }

    .index-links{
        .link-slider{
            margin: 0 -5px;
        }
        .img-box{
            padding: 0 5px;
        }
        .slick-arrow{
            font-size: 1.25rem /* 20/16 */;
        }
        .slick-prev{
            left: -10px;
        }
        .slick-next{
            right: -10px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index-head{
        margin-bottom: 25px;
    }

    .index-video-news {
        padding: 40px 0;
        .video-content{
            padding: 0;
            .main-text .video-title{
                font-size: 1.25rem /* 20/16 */;
            }
            .des{
                font-size: 1rem /* 16/16 */;
            }
        }
        .news {
            .date{
                border-top-right-radius: 20px;
                padding: 10px 5px;
                width: 70px;
                .day{
                    font-size: 1.375rem /* 22/16 */;
                }
                .month{
                    font-size: .875rem /* 14/16 */;
                }
            }
            .content{
                padding-left: 10px;
                width: calc(100% - 70px);
            }
        }
    }

    .index-download{
        .download-list{
            justify-content: space-around;
        }
        .download{
            &:nth-child(n+3){
                margin-top: 30px;
            }
        }
    }

    .index-contact{
        .data-list{
            .data-box{
                width: 100%;
            }
        }
    }

    .index-links{
        padding: 50px 0;
    }
}

// @media (max-width: $w_mo){ }