/* -------------- pagination --------------*/
.page_box {
    @include flex($jfc: center);
    margin-top: 40px;

    .page_num {
        @include size(36px);
        @include flex($ai: center, $jfc: center);
        background: $c_white;
        border-radius: 50%;
        color: var(--color-font-light);
        font-size: 1rem
            /* 16/16 */
        ;
        margin-left: 5px;
        margin-right: 5px;

        &:hover {
            background: var(--color-main);
            color: var(--color-white);
        }

        &.active {
            background: var(--color-active);
            color: var(--color-white);
        }

        i {
            font-size: 1rem
                /* 16/16 */
            ;
        }
    }
}